// @ts-nocheck
import moment from 'moment'

export default {
  /**
   * @function getCurrentSearch
   */
  getCurrentSearch (state) {
    return state.currentSearch
  },
  /**
   * @function getSavedSearches
   * @returns {array} searches
   */
  getSavedSearches (state) {
    return state.searches
  },
  /**
   * Return all the offers currently stored
   * @function getOffersList
   * @returns {array} offers
   */
  getOffersList (state) {
    return state.offers.filter(offer => offer.uuid)
  },
  getOffersSortType (state) {
    return state.offersSortType
  },
  /**
   * Returns all the offers currently stored, sorted with the current sort value
   * @function getSortedOfferslist
   * @returns {array} sortedOffers
   */
  getSortedOffersList (state, getters) {
    const sort = getters.getOffersSortType

    /**
     * Available sort functions for offers.
     */
    const sortFunctions = {
      expires_at: (a, b) => moment(a.expires_at) - moment(b.expires_at),
      '-expires_at': (a, b) => moment(b.expires_at) - moment(a.expires_at),
      price: (a, b) => a.pricing.price - b.pricing.price,
      '-price': (a, b) => b.pricing.price - a.pricing.price,
      pickup_date: (a, b) => moment(a.pickup.time_slot.date) - moment(b.pickup.time_slot.date),
      '-pickup_date': (a, b) => moment(b.pickup.time_slot.date) - moment(a.pickup.time_slot.date),
      delivery_date: (a, b) => moment(a.delivery.time_slot.date) - moment(b.delivery.time_slot.date),
      '-delivery_date': (a, b) => moment(b.delivery.time_slot.date) - moment(a.delivery.time_slot.date)
    }

    const sortFunctionName = sort || 'expires_at'
    const sortFunction = sortFunctions[sortFunctionName]

    return [...getters.getOffersList].sort(sortFunction)
  },
  /**
   * Returns the current selected offer from the offers list
   * @function getCurrentOffer
   * @returns {object} offer
   */
  getCurrentOffer (state) {
    const offer = state.offers.findIndex(offer => offer.uuid === state.currentOffer)
    if (offer !== -1) {
      return state.offers[offer]
    }
  },
  /**
   * Returns the current selected offer is available for booking
   * @function isCurrentOfferAvailableForBooking
   * @returns {boolean}
   */
  isCurrentOfferAvailableForBooking (state, getters) {
    return getters.getCurrentOffer && ((typeof getters.getCurrentOffer.available_for_booking === 'undefined') || getters.getCurrentOffer.available_for_booking)
  },
  /**
   * Returns the current selected proposal from the proposals list
   * @function getCurrentProposal
   * @returns {object} proposal
   */
  getCurrentProposal (state) {
    const proposal = state.proposals
      .findIndex(proposal => proposal.uuid === state.currentProposal)
    if (proposal !== -1) {
      return state.proposals[proposal]
    }
  },
  getProposals (state) {
    return state.proposals
      .filter(proposal => proposal.uuid)
  },
  /**
   * TODO: Is this getter really usefull? Since the getCurrentOffers returns undefined
   * if the offer is not found in the offers list.
   *
   * Returns if there is a current offer uuid
   * @function hasCurrentOfferUuid
   * @returns {boolean} hasUuid
   */
  hasCurrentOfferUuid (state, getters) {
    return getters.getCurrentOffer && !!getters.getCurrentOffer.uuid
  },
  /**
   * Returns if the keyboard navigation is active or not
   * @function isKeyboardActive
   * @returns {boolean} keyboardStatus
   */
  isKeyboardActive (state) {
    return state.keyboardStatus
  },
  /**
   * Returns all the stored itineraries
   * @function getOffersItineraries
   * @returns {array} itineraries
   */
  getOffersItineraries (state) {
    return state.itineraries
  },
  getSelectedDimension (state) {
    return state.selectedDimension
  },
  getProposalsFilter (state) {
    return state.proposalState
  },
  getProposalsMetrics (state) {
    return state.proposalsMetrics
  },
  getProposalsMeta (state) {
    return state.proposalsMeta
  },
  getOffersMetrics: state => state.offersMetrics,
  /**
   * @function isSameZones
   * @returns {boolean}
   */
  isSameZones (state) {
    return state.sameZonesManual
  },
  getOfferPreviousRoute: state => state.previousRoute
}
