<template>
  <ctk-alert-banner
    :title="$t('app.new_carrier_platform_banner.title')"
    variant="info"
  >
    <p class="tw-mb-0">
      {{ $t('app.new_carrier_platform_banner.paragraph') }}
    </p>
  </ctk-alert-banner>
</template>

<script>
  import CtkAlertBanner from '@/components/CtkAlertBanners/_subs/CtkAlertBanner/index.vue'

  /**
   * @module component - CtkNewCarrierPlatformBanner
   */
  export default {
    name: 'CtkNewCarrierPlatformBanner',
    components: {
      CtkAlertBanner
    }
  }
</script>
